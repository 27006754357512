.nav-bar {
    position: relative;
    padding-left: 5vw;
    padding-right: 10vw;
    display: flex;
    width: 100vw;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px 5px rgba(0,0,0,0.02);
  }
  .nav-bar-link {
    font-family: "Roboto", Arial;
    display: inline-block;
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-align: center;
    width: 100%;
    font-size: 1.3em;
    text-decoration: none;
    color: black;
    font-weight: 100;
  }
  .nav-bar-link:visited {
    color: black;
  }
  .nav-bar-link:hover {
    cursor: pointer;
    background-color: rgb(242,242,242);
    animation: fadein 0.3s ease;
  }
  .nav-bar-link.on-this-link {
    color: var(--docusign-blue);
    border-bottom: 2px solid var(--docusign-blue);
  }