
#successful-documents-list-container li {
    padding-bottom: 0.5em;
    width: 80%;
}


#little-checkmark-image {
    position: relative;
    top: 6px;
    width: 25px;
    margin-right: 2px;
}