.config-edit-section {
  display: flex;
}
.config-edit-section h3 {
  padding-left: 22px;
  padding-top: 1vh;
  font-family: Arial;
}
.config-editor-textarea {
    margin-top: 20px;
    margin-left: 2vw;
    padding-left: 4px;
    padding-top: 4px;
    width: 50vw;
    height: 65vh;
    border-radius: 3px;
    border: 3px solid transparent;
    outline: 1px solid lightgray;
    line-height: 1.4;
    color:rgb(28,28,28);
}
.config-editor-textarea:focus {
  outline: 1px solid gray;
}
.config-editor-textarea[jsonisvalid="false"] {
  outline: 2px solid red;
}
.config-edit-instructions {
  font-family: Arial;
  margin-top: 0vh;
  width: 42vw;
  height: 46vh;
}
.config-edit-instructions li {
  margin-top: 2ch;
}
#config-submit-button {
  margin-top: 18px;
  margin-left: 15vw;
}
.config-submission-result {
  font-family: Arial;
  margin-top: 2vh;
  text-align: center
}
#config-submission-checkmark-image {
  position: relative;
  width: 2.4ch;
  top: 3px;
  left: 3px;
}