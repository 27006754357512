.form-container h2 {
    margin-top: 7vh;
    font-family: Arial;
    text-align: center;
    padding-left: 0px;
    font-size: 1.4rem;
    color: rgb(40,40,40);
  }
  .homepage-form {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-right: 1px solid lightgray;
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .file-selector {
    font-family: Arial;
    font-weight: 400;
    display: block;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    height: 35vh;
    max-width: 80%;
    min-width: 30vw;
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border: none;
    font-size: 1.5em;
    text-align: center;
    padding-top: 3vh;
    box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.04);
    border: 1px solid rgb(233,233,233);
    color: rgb(122, 140, 148);
    font-family: sans-serif;
  }
  .file-selector:hover {
    display: block;
    cursor: pointer;
    background-color: var(--docusign-light-blue);
  }
  .folder-open-svg {
    margin-top: 4vh;
  }