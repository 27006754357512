
.help-section h2 {
    margin-top: 7vh;
    font-weight: 700;
    font-size: 1.3rem;
  }
  .instruction-texts {
    margin-top: 7vh;
    padding-left: 6vw;
    box-sizing: border-box;
    width: 80vw;
    font-family: Arial;
    font-size: 1.2rem;
  }
  .instruction-texts li {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .inline-blue {
    color: var(--docusign-blue);
  }