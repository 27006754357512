#tbg-btn-1 {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    width: 100px;
  }
  #tbg-btn-2 {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 100px;
  }
  [type='radio'] {
    display: none; 
  }
  .toggle-environment {
    width: 260px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }