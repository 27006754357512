#errored-documents-list-container li {
    padding-bottom: 0.5em;
    font-size: 1rem;
    font-family: "Roboto", Arial;
}

#red-x-image {
    position: relative;
    top: 4px;
    width: 20px;
    margin-right: 5px;
}

.link-to-edit-lock {
    font-family: Arial;
    font-size: 0.9rem;
    margin-left: 1ch;
    color: var(--docusign-blue);    
}