#job-report-container {
    display: flex;
    width: 88vw;
    margin-left: 8vw;
    box-sizing: border-box;
}

.documents-list-container {
    width: 100%;
    padding-top: 4vh;
    font-family: Arial;
}

.link-to-template {
    font-family: "Roboto", Arial;
    font-size: 1rem;
    margin-left: 1ch;
    color: var(--docusign-blue);
}
.link-to-template-icon {
    position: relative;
    top: 2px;
    width: 1.5ch;
    margin-left: 0.5ch;
}