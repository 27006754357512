:root {
  --docusign-blue: rgb(36,99,209);
  --docusign-blue-darker: rgb(33, 91, 190);
  --docusign-yellow: rgb(255,206,0);
  --docusign-light-blue: rgb(235,245,252);
  --toggle-button-background-selected: rgb(246, 252, 255);
  --toggle-button-background-unselected: rgb(255, 255, 255);
  --toggle-button-border-color-unselected: rgb(187, 187, 187);
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../font/Roboto-Black.ttf") format("truetype");
  font-display: swap;
}
@font-face {
font-family: "Roboto";
src: url("../font/roboto-v27-latin-regular.ttf") format("truetype"),
     url("../font/roboto-v27-latin-regular.woff") format("woff"),
     url("../font/roboto-v27-latin-regular.woff2") format("woff2");
font-display: swap;
}

body {
  margin-top: 0px;
  margin-left: 0px;
  overflow-x: hidden;
}


.top-bar {
  background-color: rgb(48, 48, 48);
}
h1 {
  font-family: "Roboto-Black", Arial;
  display: inline-block;
  padding-left: 5vw;
  box-sizing: border-box;
  padding-top: 3vh;
  padding-bottom: 3vh;
  margin-bottom: 0px;
  margin-top: 0px;
  color: white;
  font-size: 1.5em;
  font-weight: 900;
  width: 85vw;
}
.logout-button {
  font-family: Arial;
  font-size: 1.1rem;
  color: rgb(197, 197, 197);
  text-decoration: underline;
}
.logout-button:hover {
  cursor: pointer;
  color: rgb(210,210,210);
}
.title-link {
  text-decoration: none;
}
.title-link:visited {
  color: white;
}
#non-bold-h1 {
  color: lightgray;
  margin-left: 1ch;
  font-family: Arial;
  font-weight: 100;
  font-size: 1.3rem;
}

.btn{
  display:inline-block;
  font-weight:400;
  line-height:1.5;
  color:#212529;
  text-align:center;
  text-decoration:none;
  vertical-align:middle;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
  background-color:transparent;
  border:1px solid transparent;
  padding:.375rem .75rem;
  font-size:1rem;
  transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
  .btn{
      transition:none
  }
}
.btn:hover{
  color:#212529
}
.btn-check:focus+.btn,.btn:focus{
  outline:0;
  box-shadow:0 0 0 .25rem rgba(13,110,253,.25)
}
.btn.disabled,.btn:disabled,fieldset:disabled .btn{
  pointer-events:none;
  opacity:.65
}
.btn-primary{
  font-family: "Roboto", Arial;
  color: var(--toggle-button-color-unselected);
  background-color:var(--toggle-button-background-unselected);
  border-color: var(--toggle-button-border-color-unselected);
  font-weight: 400;
}
.btn-check:active+.btn-primary,.btn-check:checked+.btn-primary,.btn-primary.active,.btn-primary:active,.show>.btn-primary.dropdown-toggle{
  color:var(--docusign-blue);
  background-color:var(--toggle-button-background-selected);
  border-color: var(--docusign-blue);
  font-weight: 600;
}
.btn-check:active+.btn-primary:focus,.btn-check:checked+.btn-primary:focus,.btn-primary.active:focus,.btn-primary:active:focus,.show>.btn-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .25rem rgba(49,132,253,.5)
}

.btn-check:active+.btn-secondary:focus,.btn-check:checked+.btn-secondary:focus,.btn-secondary.active:focus,.btn-secondary:active:focus,.show>.btn-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .25rem rgba(130,138,145,.5)
}



@keyframes fadein {
  0% {
    background-color: white;
  }
  100% {
    background-color: rgb(242,242,242);
  }
}

