
#list-of-files-container {
  display: block;
  width: 100%;
}
#list-of-files-container[visible="false"] {
  display: none;
}

#list-of-files-container h2 {
  margin-top: 7vh;
  font-family: Arial;  
  padding-left: 4vw;
  color: rgb(40,40,40);
}

#list-of-files {
  font-family: Arial, sans-serif;
  padding-left: 5vw;
  height: 45vh;
  overflow: auto;
  width: 37vw;
}
#list-of-files li {
  color: rgb(40,40,40);
  padding-top: 4px;
  padding-bottom: 4px;
}
#file-upload-button {
  margin-left: 4vw;
  margin-top: 5vh;

}
.submit-button {
  display: inline-block;
  font-family: Arial;
  border: none;
  background-color: var(--docusign-blue);
  color: white;
  padding: 11px 27px;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
}
.submit-button:hover {
  background-color: var(--docusign-blue-darker);
  cursor: pointer;
}


.homepage-main-content-container {
  background-color: rgb(255, 255, 255);
  margin-top: 0px;
  box-sizing: border-box;
  display: flex;
}


.file-select-msg {
  margin-top: 3vh;
  color: rgb(167, 167, 167);
  font-size: 0.7em;
}
.trash-can-icon {
  width: 1.2rem;
  position: relative;
  float: right; 
}
.trash-can-icon:hover {
  cursor: pointer;
}