.waiting-page-cotainer {
  overflow-y: auto;
}

#processing-section-container {
    position: relative;
    margin-top: 8vh;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1vh;
    padding-bottom: 8vh;
    background-color: white;
    border-radius: 7px;
    
    box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.04);
}
#processing-text {
    position: relative;
}
h2 {
    font-family: Arial;
    padding-left: 6vw;

}
#job-status-message {
    font-family: Arial;
    padding-left: 6vw;
}



#progress-bar {
    margin-top: 5vh;
    width: 80vw;
    height: 40px;
    margin-left: 6vw;
}


.zip-download-link {
    font-family: "Roboto", Arial;
    font-size: 1rem;
    margin-left: 3ch;
    color: rgb(114, 114, 114);
}
.download-icon {
    position: relative;
    top: 4px;
    width: 2ch;
    height: 20px;
    margin-right: 0.4ch;
}

.progress{
    display:flex;
    height:1.2rem;
    overflow:hidden;
    font-size:.75rem;
    background-color:#e9ecef;
    border-radius:1rem;
    width: 80vw;
    margin-left:  6vw;
    margin-top: 33px;
}
.progress-bar{
    display:flex;
    flex-direction:column;
    justify-content:center;
    overflow:hidden;
    color:#fff;
    text-align:center;
    white-space:nowrap;
    background-color:#0d6efd;
    transition:width .6s ease;
}
@media (prefers-reduced-motion:reduce){
    .progress-bar{
        transition:none
    }
}
.progress-bar-striped{
    background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size:1rem 1rem
}
.progress-bar-animated{
    -webkit-animation:1s linear infinite progress-bar-stripes;
    animation:1s linear infinite progress-bar-stripes
}
@media (prefers-reduced-motion:reduce){
    .progress-bar-animated{
        -webkit-animation:none;
        animation:none
    }
}
@-webkit-keyframes progress-bar-stripes{
    0%{
        background-position-x:1rem
    }
}
@keyframes progress-bar-stripes{
    0%{
        background-position-x:1rem
    }
}