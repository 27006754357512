
#login-top-section-container{
    position: relative;
    display: block;
    box-sizing: border-box;
    margin-top: 2vh;
    margin-bottom: 2.5vh;
    padding-left: 20vw;
    padding-right: 20vw;
}
#login-top-section-container h2{
    padding-left: 35px;
}
#login-form-container {
    position: relative;
    height: 180px;
    margin-left: 35px;
}
.login-input {
    width: 70%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 19px;
    animation: castShadow2 0.5s ease;
    box-shadow: 0px 0px 7px 1px rgba(146, 146, 146, 0.2);
    border: none;
}
.login-button {
    display: inline-block;
    font-family: Arial;
    border: none;
    background-color: var(--docusign-blue);
    color: white;
    padding: 8px 27px;
    font-weight: 600;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
}
.login-button:hover {
    background-color: var(--docusign-blue-darker);
    cursor: pointer;
}


.outline-subtle:focus {
    outline: 2px solid rgba(0, 0, 0, 0.05);
    outline-offset: 1px;
  }
  .outline-subtle-red {
    outline: 2px solid rgba(255,0,0, 0.2);
  }